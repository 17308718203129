import styled from 'styled-components';
import { outer } from '../shared';
import { colors } from '../colors';
 
export const ErrorCode = styled.h1`
  margin: 0;
  font-size: 12vw;
  line-height: 1em;
  letter-spacing: -5px;
  opacity: 0.3;
`;

export const ErrorDescription = styled.p`
  margin: 0;
  color: ${colors.midgrey};
  font-size: 3rem;
  line-height: 1.3em;
  font-weight: 400;
`;

export const ErrorTemplate = styled.main`
  padding: 7vw 4vw;

  ${({ useOuter }) => useOuter && outer};
`;