import React from 'react';
import Helmet from 'react-helmet';
import styled, { css } from 'styled-components';
import { graphql, Link } from 'gatsby';

import SiteNavLogo from '../blog/components/Header/SiteNavLogo';
import PostCard from '../blog/components/PostCard';
import Wrapper from '../blog/components/Wrapper/Wrapper';
import IndexLayout from '../layouts';

import { ROUTES } from '../routes';

import { ErrorCode, ErrorDescription, ErrorTemplate } from '../styles/pages/404.styles';
import { inner, outer, PostFeed, SiteHeader } from '../styles/shared';

const SiteNavCenter = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .site-nav-logo {
    margin-right: 0;
  }
`;

const errorLink = css`
  display: inline-block;
  margin-top: 5px;
`;

const NotFoundPage = props => {
  const { edges } = props.data.allMarkdownRemark;

  return (
    <IndexLayout>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <meta name="robots" content="noindex, follow" />
        <meta property="og:title" content="Page not found - David Nowak" />
        <meta property="og:site_name" content="David Nowak" />
        <title>Page not found - David Nowak</title>
        <script
          async
          defer
          data-domain="davidpnowak.com"
          src="https://analytics.pixeljab.com/js/plausible.js"
        />
      </Helmet>
      <Wrapper>
        <SiteHeader css={outer}>
          <div className="inner">
            <SiteNavCenter>
              <SiteNavLogo />
            </SiteNavCenter>
          </div>
        </SiteHeader>
        <ErrorTemplate useOuter>
          <div css={inner}>
            <section style={{ textAlign: 'center' }}>
              <ErrorCode>404</ErrorCode>
              <ErrorDescription>Page not found</ErrorDescription>
              <Link css={errorLink} to={ROUTES.BLOG.toUrl()}>
                Go to the Blog Home page →
              </Link>
            </section>
          </div>
        </ErrorTemplate>
        <aside css={outer}>
          <div css={inner}>
            <div css={PostFeed}>
              {edges.map(({ node }) => (
                <PostCard key={node.fields.slug} post={node} />
              ))}
            </div>
          </div>
        </aside>
      </Wrapper>
    </IndexLayout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 3, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          timeToRead
          frontmatter {
            title
            date
            userDate: date(formatString: "MMMM DD, YYYY")
            tags
            image {
              childImageSharp {
                gatsbyImageData(width: 3720)
              }
            }
            author {
              name
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    gatsbyImageData(quality: 90)
                  }
                }
              }
            }
          }
          excerpt
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;
